<template>
  <div class="payitem">
      Payment Item
      <el-dialog title="Payment Item" :visible.sync="dialogFormVisible">

        <el-form :model="selectedPayItem">
          <el-input v-model="selectedPayItem.payItem" autocomplete="off"></el-input>
        </el-form>
        
        <div slot="footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="Confirm">Confirm</el-button>
                   
        </div>
      </el-dialog>

      <el-row :gutter="20">
         <el-col :span="12"><el-input v-model="payItem" placeholder="Please Input Payment Item"></el-input></el-col>
        <el-col :span="2"><el-button type="primary" size="medium" plain round @click="add">Add</el-button></el-col>
      </el-row>

      <el-row>
        <el-table
        border
        style="width:870px"
        :data="payItems">
        <el-table-column type="index" fixed></el-table-column>
          <el-table-column width= "600" label="Payment Item" prop="payItem"></el-table-column>
        
        <el-table-column
            fixed="right">
          <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="success" size="medium" plain round>Modify</el-button>
          <el-button @click.native.prevent="deleteRow(scope.$index, payItems)"
          type="danger" size="medium" plain round>Delete</el-button>
          </template>
        </el-table-column>
        
        </el-table>
      </el-row>
     
  
  </div>
</template>

<script>
import { axiosInstance } from '@/api'
export default {
    name: 'PayItem',
    data() {
      return {
        payItem: "",
        payItems: [],
        dialogFormVisible: false,
        
        selectedPayItem: {
          payItem:''}
      
      }
    },

    created(){
        this.loadData()
    },
    methods:{
      add(){
          axiosInstance.post("/payItem",{payItem: this.payItem}).then((response) => {
           this.loadData()
          })
      },
      loadData(){
          axiosInstance.get("/payItem").then((response) => {
            this.payItems = response.data;
          })
      },
      handleClick(row) {
        this.selectedPayItem = row;
        this.dialogFormVisible =true;
        console.log(row);
      },
      
      Confirm(){
        axiosInstance.put("/payItem/"+this.selectedPayItem.id,{payItem: this.selectedPayItem.payItem}).then((response) => {
           this.loadData()
        }).finally(()=>{
           this.dialogFormVisible =false;
        })
    },

      deleteRow(index, rows) {
        let row = rows[index];
        this.$confirm('Delete Or Not?', 'CHECK', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          axiosInstance.delete("/payItem/"+row.id).then((response) => {
            rows.splice(index, 1);
            this.$message({
            type: "success",
            message: 'Success!!'
          });
          })
         
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancel'
          });          
        });
        
      },

     

    }
}
</script>

<style lang="scss" scoped>
.payitem{
  .el-row {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }

}

</style>